class ZIDXAccountCloudFiles implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountCloudFilesContainer";
    }
    getMatchURL(){
        return "/account/settings/cloud-files";
    }
    render(){
        // console.log("cloud files");
    }
}